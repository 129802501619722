import { useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';

import { SHA1 } from '../utils/cryptography';
import { protectedResources, useAccessToken } from '../msal';
import { RequestStatus } from './types';
import { UnauthorisedError, ConditionalAccessError } from '../errors';
import { setElogPin } from './elogClient';

const { scopes } = protectedResources.elogPinApi;

export function useElogPin() {
  const [status, setStatus] = useState(RequestStatus.Idle);
  const { acquireAccessToken } = useAccessToken();
  const { instance, accounts } = useMsal();
  const tokenClaims = useAccount(accounts[0])?.idTokenClaims;
  const employeeId = (tokenClaims as any).employeeId;

  const setPin = async (pin: string) => {
    try {
      setStatus(RequestStatus.Pending);

      const hashedPin = SHA1.hash(pin, employeeId);
      const accessToken = await acquireAccessToken(scopes);

      await setElogPin(accessToken, hashedPin);

      setStatus(RequestStatus.Success);
    } catch (error) {
      if (error instanceof ConditionalAccessError) {
        const tokenRequest = { scopes, claims: error.claims };

        await instance.acquireTokenRedirect(tokenRequest);
      }

      if (error instanceof UnauthorisedError) {
        await instance.loginRedirect();
      }

      setStatus(RequestStatus.Failure);
    }
  };

  return {
    setPin,
    status,
  };
}
