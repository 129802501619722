import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';

export function useAccessToken() {
  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0]);

  const acquireAccessToken = async (scopes: string[] = []) => {
    try {
      if (!account) {
        throw new Error(
          'An authenticated account is required to retrieve an access token.'
        );
      }

      if (inProgress !== InteractionStatus.None) {
        throw new Error('A msal interaction is currently in progress.');
      }

      const { accessToken } = await instance.acquireTokenSilent({
        scopes,
        account,
      });

      return accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect({
          scopes,
        });
      }

      throw error;
    }
  };

  return {
    acquireAccessToken,
  };
}
