/* eslint-disable @airnz/rules/no-airnz-ui-internals */
import React, { useState } from 'react';
import { FormSubmitHandler } from '@airnz/ui/forms/Form';

import { useElogPin } from '../../lib/api';
import FormToastDialog from './FormToastDialog';
import { RequestStatus } from '../../lib/api/types';
import ElogFormFields, { ElogFormFieldValues } from '../ElogFormFields';

export default function ElogForm() {
  const { setPin, status } = useElogPin();
  const [hasDismissedToast, setHasDismissedToast] = useState(false);

  const isAlertVisible =
    status === RequestStatus.Success || status === RequestStatus.Failure;

  const handleSubmit: FormSubmitHandler<ElogFormFieldValues> = async (
    { pin },
    { restart }
  ) => {
    setHasDismissedToast(false);
    await setPin(pin);
    restart();
  };

  const dismissToast = () => setHasDismissedToast(true);

  return (
    <>
      <FormToastDialog
        isSuccess={status === RequestStatus.Success}
        open={isAlertVisible && !hasDismissedToast}
        onClose={dismissToast}
      />

      <ElogFormFields handleSubmit={handleSubmit} />
    </>
  );
}
