export enum RequestStatus {
  Idle = 'IDLE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';

export interface RequestOptions<TPayload> {
  method: HttpMethod;
  headers: Headers;
  body: TPayload;
}

export type PostOptions<TPayload> = Pick<RequestOptions<TPayload>, 'headers'>;
