import React from 'react';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
} from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import AppRoot from '@airnz/ui/AppRoot';
import PageHeading from '@airnz/ui/PageHeading';
import PageDescription from '@airnz/ui/PageDescription';
import LoadingSpinner from '@airnz/ui/LoadingSpinner';
import Alert from '@airnz/ui/Alert';

import { loginRequest, msalConfig, buildAuthErrorMessage } from './lib/msal';
import PageLayout from './components/PageLayout';
import ElogForm from './components/ElogForm';

import '@airnz/ui/fonts';

const msalInstance = new PublicClientApplication(msalConfig);

const Loading = () => <LoadingSpinner />;
const AuthError = (authErrorResult: MsalAuthenticationResult) => (
  <Alert type="danger">{buildAuthErrorMessage(authErrorResult)}</Alert>
);

export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AppRoot locale={'en-NZ'}>
        <PageLayout>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={Loading}
            errorComponent={AuthError}
            authenticationRequest={loginRequest}
          >
            <PageHeading>Welcome to the eLog 787 PIN setup</PageHeading>

            <PageDescription>Set your new PIN</PageDescription>

            <ElogForm />
          </MsalAuthenticationTemplate>
        </PageLayout>
      </AppRoot>
    </MsalProvider>
  );
}
