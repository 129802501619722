/* eslint-disable @airnz/rules/no-airnz-ui-internals */
import React from 'react';
import Form, { FormSubmitHandler } from '@airnz/ui/forms/Form';
import TextFormField from '@airnz/ui/forms/TextFormField';
import Button from '@airnz/ui/Button';
import PageActions from '@airnz/ui/PageActions';
import useValidators from '@airnz/ui/forms/validation/useValidators';
import useMinLengthValidator from '@airnz/ui/forms/validation/useMinLengthValidator';
import useMaxLengthValidator from '@airnz/ui/forms/validation/useMaxLengthValidator';
import { Validator } from '@airnz/ui/forms/validation';
import { createBem } from '@airnz/ui/bem';

import { RequestStatus } from '../../lib/api/types';
import { pinMatchValidator, INITIAL_VALUES } from './ElogFormFields.util';
import { ElogFormFieldValues } from './ElogFormFields.types';

export interface ElogFormFieldsProps {
  handleSubmit: FormSubmitHandler<ElogFormFieldValues>;
}

const bem = createBem('elog-ElogFormFields');

const ElogFormFields = ({ handleSubmit }: ElogFormFieldsProps) => {
  const minLengthValidator = useMinLengthValidator(6);
  const maxLengthValidator = useMaxLengthValidator(255);

  const pinLengthValidators = useValidators(
    minLengthValidator,
    maxLengthValidator
  ) as Validator<string, string, object>;

  const pinConfirmationValidators = useValidators(
    pinLengthValidators,
    pinMatchValidator
  ) as Validator<string, string, object>;

  return (
    <Form initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      <TextFormField
        type="password"
        name="pin"
        label="New PIN"
        required
        validator={pinLengthValidators}
        className={bem('pin')}
      />

      <TextFormField
        type="password"
        name="pinConfirmation"
        label="Re-enter PIN"
        required
        validator={pinConfirmationValidators}
        className={bem('pinConfirmation')}
      />

      <PageActions>
        <Button primary submit inProgress={status === RequestStatus.Pending}>
          Update PIN
        </Button>
      </PageActions>
    </Form>
  );
};

export default ElogFormFields;
