import React, { ReactNode } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import HeaderBase from '@airnz/ui/HeaderBase';
import InternalAppHeaderLogo from '@airnz/ui/InternalAppHeaderLogo';
import Footer from '@airnz/ui/Footer';
import PageBody from '@airnz/ui/PageBody';
import PageBlock from '@airnz/ui/PageBlock';
import useBreakpoints from '@airnz/ui/useBreakpoints';
import HeaderPopover from './HeaderPopover/HeaderPopover';

interface PageLayoutProps {
  children: ReactNode;
}

export default function PageLayout({ children }: PageLayoutProps) {
  const { sm } = useBreakpoints();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <HeaderBase
        topLeft={
          <InternalAppHeaderLogo size={sm ? 'md' : 'sm'}>
            eLog Electronic Logbook
          </InternalAppHeaderLogo>
        }
        topRight={isAuthenticated && <HeaderPopover />}
      />

      <PageBody>
        <PageBlock>{children}</PageBlock>
      </PageBody>

      <Footer />
    </>
  );
}
