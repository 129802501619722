import { appConfig as env } from '../utils';

export const msalConfig = {
  auth: {
    clientId: env.clientId || '',
    authority: `https://login.microsoftonline.com/${env.tenantId}`,
    redirectUri: env.redirectUri,
  },
  cache: {
    cacheLocation: env.cacheLocation,
    storeAuthStateInCookie: env.storeAuthStateInCookie,
  },
};

const updateScope = `api://${env.elogPinApiClientId}/elog.write`;

export const loginRequest = {
  scopes: [updateScope],
};

export const protectedResources = {
  elogPinApi: {
    scopes: [updateScope],
  },
};
