const MESSAGE = 'Interaction required to grant conditional access.';

export class ConditionalAccessError extends Error {
  claims: string;

  constructor(claims: string) {
    super(MESSAGE);
    this.claims = claims;
  }
}
