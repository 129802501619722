import { ElogFormFieldValues } from './ElogFormFields.types';

export const PIN_MISMATCH_MESSAGE = 'PIN does not match.';

export const pinMatchValidator = (
  value: string,
  { pin }: ElogFormFieldValues
) => (value !== pin ? PIN_MISMATCH_MESSAGE : undefined);

export const INITIAL_VALUES = { pin: '', pinConfirmation: '' };
