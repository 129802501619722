import React from 'react';
import Alert from '@airnz/ui/Alert';
import Button from '@airnz/ui/Button';
import ToastDialog from '@airnz/ui/ToastDialog';

export interface FormToastDialogProps {
  isSuccess: boolean;
  open: boolean;
  onClose(): void;
  errorMessage?: string;
}

const SUCCESS_MESSAGE = 'You’re all set! You can now close this page.';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export default function FormToastDialog({
  isSuccess,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  open,
  onClose,
}: FormToastDialogProps) {
  const type = isSuccess ? 'success' : 'danger';
  const text = isSuccess ? SUCCESS_MESSAGE : errorMessage;

  return (
    <ToastDialog
      open={open}
      onClose={onClose}
      actions={<Button onClick={onClose}>OK</Button>}
    >
      <Alert minimal type={type}>
        {text}
      </Alert>
    </ToastDialog>
  );
}
