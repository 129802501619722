import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

export const main = () => {
  const rootEl = document.getElementById('root');
  ReactDOM.render(<App />, rootEl);

  // Hot Module Replacement
  if (module.hot) {
    module.hot.accept('./App', () => {
      import('./App').then((app: any) => {
        const NextApp = app.default;
        ReactDOM.render(<NextApp />, rootEl);
      });
    });
  }
};

main();
