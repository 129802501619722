import * as Crypto from 'crypto-js';

export function hash(value: string, salt = ''): string {
  // To encode characters into binary string to avoid handling unexpected characters
  const joinedUTF = Crypto.enc.Utf8.parse(value + salt);

  // Hash and convert to Word Array
  const joinedUTFHash = Crypto.SHA1(joinedUTF);

  // Base64 encoding based on the pin hashing way Boeing suggest
  const joinedUTFBased64Hash = Crypto.enc.Base64.stringify(joinedUTFHash);
  return joinedUTFBased64Hash;
}
