import {
  UnauthorisedError,
  BadRequestError,
  ConditionalAccessError,
} from '../errors';

export async function setElogPin(accessToken: string, hashedPin: string) {
  const payload = {
    hashedPin,
  };

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };

  const response = await fetch('api/elog-pin/v1/elog-pin', options);

  if (response.ok) {
    return;
  }

  const jsonResponse = await response.json();
  switch (response.status) {
    case 403:
      const claims =
        jsonResponse.messageCodes[0].messageTemplate.parameters.claims;
      throw new ConditionalAccessError(claims);
    case 401:
      throw new UnauthorisedError();
    case 422:
      throw new BadRequestError();
    default:
      throw new Error();
  }
}
