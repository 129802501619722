import React, { useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import HeaderUserPopover from '@airnz/ui/HeaderUserPopover';
import HeaderUserPopoverGreeting from '@airnz/ui/HeaderUserPopoverGreeting';
import HeaderUserPopoverSection from '@airnz/ui/HeaderUserPopoverSection';
import Button from '@airnz/ui/Button';

export default function HeaderPopover() {
  const [isOpen, setIsOpen] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const userName = account?.name;

  function handleSignout() {
    instance.logoutRedirect();
  }

  return (
    <HeaderUserPopover
      open={isOpen}
      userName={userName}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <HeaderUserPopoverSection>
        <HeaderUserPopoverGreeting name={userName?.split(' ')[0]} />
      </HeaderUserPopoverSection>

      <HeaderUserPopoverSection>
        <Button size="sm" onClick={handleSignout}>
          Sign out
        </Button>
      </HeaderUserPopoverSection>
    </HeaderUserPopover>
  );
}
