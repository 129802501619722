export interface AppConfig {
  experienceApiBaseUrl: string;
  clientId: string;
  tenantId: string;
  redirectUri: string;
  cacheLocation: 'localStorage' | 'sessionStorage';
  storeAuthStateInCookie: boolean;
  elogPinApiClientId: string;
  elogPinUiJwt: string;
}

export interface AppWindow {
  appConfig?: AppConfig;
}

export const appConfig: AppConfig = (window as unknown as AppWindow)
  ?.appConfig || {
  experienceApiBaseUrl: '',
  clientId: '',
  tenantId: '',
  redirectUri: '',
  cacheLocation: 'sessionStorage',
  storeAuthStateInCookie: false,
  elogPinApiClientId: '',
  elogPinUiJwt: '',
};
